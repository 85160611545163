import React from'react';
import axios from 'axios';

export default function Blogs() {
    // Make Dev.to API calls here
    return (
        <div id="blogs">
            <h1>Coming soon!</h1>
        </div>
    );
}